<template>
    <content-view v-if="isPageLoaded">
        <div class="lesson content-centered" v-if="!isLoading">
            <div
                class="lesson__content"
            >
                <router-link :to="`/courses/${courseId}`" class="v-back">
                    <i class="v-back__icon"/>
                    К оглавлению
                </router-link>

                <div class="lesson-head">
                    <div class="lesson-head__row">
                        <div class="lesson-head__col lesson-head__col--left">
                            <span class="v-desc lesson__meta">{{ lesson.description }}</span>
                        </div>

                        <div class="lesson-head__col lesson-head__col--right">
                            <div class="lesson__toolbar">
                                <router-link
                                    v-if="isEditorCourse && $route.query.module"
                                    :to="{
                                        path: `/courses/${courseId}/${$route.query.module}/lesson/${lesson.id}/edit`,
                                        query: {
                                            module: $route.query.module
                                        }
                                    }"
                                    title="Редактировать урок"
                                    aria-label="Edit lesson"
                                    class="toolbar__button toolbar__edit"
                                >
                                    <i>
                                        <icon height="12" width="12">
                                            <path
                                                d="M0 9.49978V12.0001H2.50035L9.87802 4.62242L7.37767 2.12207L0 9.49978Z"
                                            />
                                            <path
                                                d="M11.8045 1.7519L10.2476 0.195027C9.98759 -0.065009 9.56419 -0.065009 9.30415 0.195027L8.08398 1.4152L10.5843 3.91554L11.8045 2.69537C12.0646 2.43534 12.0646 2.01193 11.8045 1.7519Z"
                                            />
                                        </icon>
                                    </i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="lesson__body">
                    <new-editor
                        :data="lesson.text"
                        :editable="false"
                        :top-title="lesson.title"
                        top-placeholder="Название урока"
                        bottom-placeholder="Описание урока"
                        class="content__editor"
                    />
                </div>

                <div
                    v-if="lesson"
                    class="lesson__controls"
                >
                    <div class="lesson__controls-row">
                        <div class="lesson__controls-col">
                            <button
                                v-if="!isEditorCourse && lesson.access"
                                class="v-btn"
                                :class="['lesson__controls-item', {
                                    'v-btn--ready': lesson.studied,
                                    'v-btn--not-ready': !lesson.studied,
                                }]"
                                @click="getReady"
                            >
                                <icon>
                                    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 6.32895L5.30519 11L14 1" stroke="white" stroke-width="2"/>
                                    </svg>
                                </icon>
                                <span class="ml-10">Изучено</span>
                            </button>
                        </div>

                        <div class="lesson__controls-col">
                            <div class="lesson__controls-nav">
                                <button @click="prevLesson" :disabled="isPrevBtnDisabled" class="lesson__controls-item v-btn v-btn--white">
                                    <svg class="mr-10" width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 1L2 6L7 11" stroke="#9D9D9E" stroke-width="2"/>
                                    </svg>
                                    <span>Предыдущий урок</span>
                                </button>

                                <button
                                    @click="nextPage"
                                    v-if="lesson.next && lesson.next.lesson"
                                    :disabled="isNextBtnDisabled || lesson.next.test_passed"
                                    class="lesson__controls-item v-btn v-btn--white"
                                >
                                    <span>Следующий урок</span>
                                    <svg class="ml-10" width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L6 6L1 11" stroke="#9D9D9E" stroke-width="2"/>
                                    </svg>
                                </button>

                                <button
                                    v-if="lesson.next && lesson.next.test && !isEditorCourse"
                                    @click="nextPage"
                                    :disabled="isNextBtnDisabled || lesson.next.test_passed"
                                    class="lesson__controls-item v-btn v-btn--white"
                                >
                                    <span>Перейти к тесту</span>
                                    <svg class="ml-10" width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L6 6L1 11" stroke="#9D9D9E" stroke-width="2"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <comments-block
                    v-if="lesson.comments_allowed"
                    :update-comments-count="() => lesson.comments.count++"
                    :comments-list="lesson.comments"
                    content-type="lesson"
                    :obj-id="lesson.id"
                    class="lesson__comments"
                />
            </div>
        </div>
        <loading-spinner v-else />
    </content-view>
</template>

<script>
import session from "@/api/session";
import { mapGetters } from "vuex";
import { eventBus } from '@/main';

export default {
    name: "lessonDetail",
    components: {
        CommentsBlock : () => import('@/components/comment/CommentsBlock'),
        Comments : () => import('@/components/wall/Comments/Comments'),
        Icon : () => import('@/components/icon/Icon'),
        ContentView : () => import('@/views/menu/ContentView'),
        NewEditor : () => import('@/components/editor/newEditor'),
        LoadingSpinner : () => import('@/components/LoadingSpinner')
    },
    data() {
        return {
            lesson: {},
            courseId: this.$route.params.course_id,
            isPageLoaded: false,
            isLoading: true
        };
    },
    computed: {
        ...mapGetters("default_data", ["isAdmin", 'isEditorCourse']),
        isNextBtnDisabled() {
            if (!this.lesson.next) {
                return true
            }
            return this.isEditorCourse ? false : !this.lesson.studied;
        },
        isPrevBtnDisabled() {
            return !this.lesson.previous
        }
    },
    async created() {
        await this.loadlesson();
    },
    mounted () {
        this.$nextTick(() => {
            this.isPageLoaded = true;
        })
    },
    methods: {
        async getReady() {
            if (!this.lesson.studied) {
                const request = await session.post(`/api/v1/course-lesson/${this.lesson.id}/studied/`);
                this.lesson.studied = true;
                if (request.data.is_finished) {
                    this.$router.push(`/courses/${this.courseId}`);
                    let finishModal = () => import(`@/components/course/modals/ModalCourseFinish`);
                    this.$modal.show(finishModal, {
                    }, {
                        name: 'course-finish',
                        scrollable: true,
                        height: 'auto',
                        adaptive: true,
                        maxWidth: 540,
                        width: '100%',
                    })
                }
            }
        },
        async loadlesson() {
            let response;
            try {
                this.isLoading = true;
                const request = session.get(`/api/v1/course-lesson/${this.$route.params.lesson_id}/`);
                response = await request;
                this.lesson = response.data;
                this.isLoading = false;
            } catch (err) {
                if (err.response.status === 404) {
                    this.$router.push(`/404`);
                }
            }
        },
        nextPage() {
            const {lesson} = this;
            //Проблемы конченой типизации JS
            if (this.isEditorCourse && !!lesson.next.lesson || lesson.studied && !!lesson.next.lesson) {
                this.$router.push(`/courses/${this.courseId}/lesson/${lesson.next.lesson}`)
            } else if (this.isEditorCourse && !!lesson.next.test || lesson.studied && !!lesson.next.test) {
                this.$router.push(`/courses/${this.courseId}/test/${lesson.next.test}`)
            }
        },

        prevLesson() {
            if (this.lesson.previous) {
                this.$router.push(`/courses/${this.courseId}/lesson/${this.lesson.previous}`)
            }
        },

        removelesson() {
            eventBus.$emit('remove-lesson', this.lesson);
        }
    },
};
</script>

<style lang="scss">
    @import "#sass/v-style";
    @import "detail";

    .content-centered {
        max-width: 800px;
        margin: 0 auto;
        @include below($tablet) {
            max-width: 100%;
        }
    }

    .lesson {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
        height: 100%;

        @include below(1000px) {
            padding: 0 10px;
        }

        ul,
        ol {
            padding-left: 1rem !important;
        }

        &-head {
            margin: 10px 0px;
            &__row {
                margin: -5px;
                display: flex;
                align-items: center;
            }

            &__col {
                margin: 5px;
            }
        }

        &__body {
            h1 {
                font-weight: 700;
                font-size: rem(40px);
            }
        }

        &__text {
            margin-top: 20px;
        }
        &__content {
            padding-bottom: 16px;
            ul {
                list-style: disc;
            }
            p {
                margin-bottom: 10px !important;
            }
        }
        &__controls {
            $p: &;

            margin-top: 40px;
            white-space: nowrap;
            &-row {
                margin: -5px;
                display: flex;
                flex-wrap: wrap;
                @media screen and (min-width: $mobile + 1px) {
                    align-items: center;
                    justify-content: space-between;
                }

                @include below($mobile) {
                    flex-direction: column;
                }
            }

            &-col {
                margin: 5px;
                @include below($mobile) {
                    flex-grow: 1;
                }
            }

            &-nav {
                margin: -5px;
                display: flex;
                flex-wrap: wrap;
                #{$p}-item {
                    margin: 5px;
                    border-radius: 4px !important;
                    border: 1px solid #DCDCDC !important;
                    @include below($mobile) {
                        flex-grow: 1;
                        width: auto;
                    }
                }

                @media screen and (min-width: $mobile + 1px) {
                    align-items: center;
                }

                @include below($mobile) {
                    flex-direction: column;
                }
            }

            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
                @include below($mobile) {
                    width: 100%;
                }
            }

            .v-btn--not-ready {
                path {
                    stroke: #50B440
                }
            }
        }

        &__comments {
            margin-top: 30px;
        }

        &__toolbar {
            display: flex;
            align-items: center;
        }
    }

    .toolbar {
        &__status {
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            padding: 7px 14px;
            margin-right: 16px;
            border-radius: 40px;

            &--active {
                background: #c8f5d8;
            }

            &--draft {
                background: #f2f2f2;
            }

            &--complete {
                background: #d5e8ff;
            }
        }

        &__results {
            display: flex;
            align-items: center;

            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            background: #ffffff;
            border: 1px solid #fbc04f;
            border-radius: 40px;
            padding: 6px 13px;
            position: relative;
            margin-right: 8px;
            cursor: pointer;

            @include below($mobile) {
                margin-left: unset;
                order: 2;
                margin-right: auto;
                margin-top: 24px;
            }

            &-icon {
                position: relative;
                top: 1px;
                width: 21px;
                height: 25px;
                padding-top: 2px;
                margin-left: 8px;

                text-align: center;
                background-image: url("data:image/svg+xml,%3Csvg width='21' height='26' viewBox='0 0 21 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.5L21 0.5L21 19.3182L10.5 25.5L0 19.3182L0 0.5Z' fill='%23FBC04F'/%3E%3C/svg%3E%0A");
            }
        }

        &__break {
            display: none;

            @include below($mobile) {
                display: block;
                flex-basis: 100%;
                height: 0;
                order: 2;
            }
        }

        &__button {
            cursor: pointer;
            display: flex;
            width: 32px;
            height: 32px;
            padding-left: 8px;
            margin: 0 0 0 12px;
            border-radius: 50%;
            border: 2px solid #e6ebf3;
            background-color: transparent;
            svg {
                color: #989cae;
                transition: all $ease;
            }
        }

        &__edit {
            &:hover {
                svg {
                    color: $gold;
                }
            }
        }

        &__delete {
            &:hover {
                svg {
                    color: $red-main;
                }
            }
        }
    }
</style>
